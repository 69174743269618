<template>
	<div class="main-contents">
		<div class="tit">수요기업 정보관리</div>
		<div class="search-box">
			<SelectComp cdId="PRO105" v-model="srchFilter.indtypeClassCd" :isAll="true" title="산업분야"/>
			<Nbsp/>
			<InputComp v-model="srchFilter.srchTxt" placeholder="수요기업명" @keyup.enter="getDemandCorpInfoList('1')" />
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getDemandCorpInfoList('1')"/>
			<div class="sub_btn" @click="$router.push('MEM921M01')">
				+ 수요기업 등록
			</div>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="5%">
					<col width="40%">
					<col width="8%">
					<col width="12%">
					<col width="25%">
					<col width="10%">
					<!-- <col width="15%"> -->
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>기업명</th>
						<th>서비스 구분</th>
						<th>사업자등록번호</th>
						<th>산업분야</th>
						<th>등록일</th>
						<!-- <th>수요기업 프로젝트 정보</th> -->
					</tr>
				</thead>
				<tbody v-if="demandCorpList.length > 0">
					<!-- 최대 25건 조회 -->
					<tr v-for="(row, rowIdx) in demandCorpList" :key="rowIdx">
						<td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (rowIdx + 1)}}</td>
						<td class="score name" @click="corpPopup(row.corpSeq)">{{row.corpNm}} &#9654;</td>
						<td class="score">{{row.contractDivCd == '00' ? '무료':'유료'}}</td>
						<td class="score">{{row.bizRegNo | bizNo}}</td>
						<td class="score">{{row.indtypeClassNm}}</td>
						<td class="score">{{row.regYmd | date('yyyy.MM.dd')}}</td>
						<!-- <td class="score resume" @click="viewProjectList(row)"><div class="btn">프로젝트 보기 &#9654;</div></td> -->
					</tr>
				</tbody>
				<tbody v-else>
					<!-- 관련내용 없을 경우 -->
					<tr>
						<td colspan="6" class="none">등록된 수요기업이 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data() {
		return {
			// 검색조건
			srchFilter : {
				indtypeClassCd : '',
				srchTxt : '',
				pageIndex : 1,
			},

			// 수요기업 목록
			demandCorpList : [],

			// 페이징
			pageInfo : {},
		}
	},

	components : {
		pagingComp
	},

	beforeMount() {
		// this.getDemandCorpInfoList()
	},

	mounted() {

		var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}

		this.getDemandCorpInfoList()
	},

	methods : {
		// 수요기업 목록 조회
		getDemandCorpInfoList(div) {
			var param = this.srchFilter;
			param.pageUnit = '25';
			param.pageSize = '10';
			if(div) param.pageIndex = 1;

			//console.log('getDemandCorpInfoList PARAM', param);

			this.$.httpPost('/api/mem/adm/dco/getDemandCorpInfoList', param)
				.then(res => {
					//console.log('getDemandCorpInfoList RESULT', res);

					this.demandCorpList = res.data.demandCorpList;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},

		// 페이징 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getDemandCorpInfoList();
		},

		corpPopup(seq) {
			var param = {};
			param.div = 'dco';
			param.corpSeq = seq;

			this.$.popup('/adm/mem/MEM921P01', param);
		}, 

		viewProjectList(corp) {
			var params = {
				corpSeq : corp.corpSeq,
				corpNm : corp.corpNm,
				caller : {
					name: this.$route.name,
					params: this.srchFilter
				}
			}

			this.$router.push({name: 'MEM922M01', params });
		}

	}
}
</script>